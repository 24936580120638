import axios from 'axios';
import JwtAuthService from './services/jwtAuthService';

//const host = 'http://localhost:3000/api';
const host = 'https://negagestion.cl/api';
const staticHost = host.replace('/api', '');

// Set ruta_files dynamically based on the host
export const ruta_files = host.includes('localhost') ? '/home/mateo/Desktop/NegaWatt/backend/files/' : '/home/negagestion/negagestion/backend/files';

const Backend = axios.create({
  baseURL: host,
  timeout: 0,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  },
  transformRequest: (data, headers) => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      headers['Authorization'] = token;
    }
    return JSON.stringify(data);
  }
});

// Validate token explicitly during app initialization
export const ValidateToken = async token => {
  try {
    const response = await Backend.get('/validateToken', {
      headers: {Authorization: token}
    });
    return response.status === 200;
  } catch (err) {
    return false;
  }
};

export default Backend;
export {host, staticHost};
